import '../static/css/_form.css'
import React        from 'react'
import { useTranslation } from "react-i18next"
import CarPoliceInfoForm from "../components/CarPoliceInfoForm"
import Main         from '../layouts/Main'
import CarInfoForm from "../components/CarInfoForm";
import CarUpdateForm from "../components/CarUpdateForm";
import {useLoad} from "../hooks/request";
import {carGetData} from "../urls";

export default function CarUpdate({ match }) {
    const { t } = useTranslation("auto")
    const { response } = useLoad({
        url:carGetData.replace('{code}', match.params.code)
    })

    if (!response)
        return (
            <div />
        )

    return (
        <Main header={"Обновление информации"}>
            <div className="main">
                <div className="container">
                    <h1 className="auto__title">
                        {t('header')}
                    </h1>
                    <CarInfoForm response={response} />
                    <CarPoliceInfoForm response={response} match={match}/>
                </div>
            </div>
        </Main>
    )
}
