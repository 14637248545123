import cn from "classnames"
import React from 'react';
import { useField } from "formik"
import { useTranslation } from "react-i18next"

export default function Input({ title, name, placeholder, isDisabled, isRequired, error, onChange = () => {}}) {
  const [ { value }, {  }, { setValue } ] = useField(name);
  const { t } = useTranslation('common');

  return (
    <div className={cn("input", {'input_error': error, 'input__required': isRequired})}>
      <label className={ cn("input__label", {'label-valid': !error}) } htmlFor={ name }>
        { title }
        <div className="input_error">
          <span className="input_error-text">
            { t('wrongInput') }
          </span>
        </div>
      </label>
      <input id={ name } placeholder={ placeholder } className="input__input" type="text"
             value={ value } disabled={ isDisabled } aria-invalid={!isDisabled}
             onChange={(e) => {
               setValue(e.target.value)
               onChange(e.target.value)
             }}/>
    </div>
  )
}
