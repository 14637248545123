import cn from "classnames"
import React from 'react';
import { useField } from "formik"

export default function Checkbox({ title, name, error }) {
  const [ { value }, { }, { setValue } ] = useField(name);

  return (
    <div className={cn("checkbox auto-order__text rules__check", {'rules__check-error': error})}>
      <label className="auto-order__rules hint__label" style={{display: "flex"}}>
        <p className="checkbox__text">
          { title }
        </p>
        <input id={ name } name={ name } className="checkbox__input" type="checkbox"
               value={value} onChange={() => setValue(!value)} />
        <span className="checkbox__span"></span>
      </label>
    </div>
  )
}
