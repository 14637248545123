import { FormikProvider, useFormik } from "formik"
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from "react-i18next"
import { useModal } from "../hooks/modal"
import { useGetRequest, usePostRequest, usePutRequest } from "../hooks/request"
import ModalCarConfirm from "../layouts/modals/ModalCarConfirm"
import { carUpdData, authUrl, carUpdateData, markUrl, modelUrl } from "../urls"
import { createRequestDataV2, validate } from "../utils/auto"
import CarUpdateForm from "./CarUpdateForm"
import Button from "./form/Button"
import Checkbox from "./form/Checkbox"
import Input from "./form/Input"
import InputDate from "./form/InputDate"
import Select from "./form/Select"

export default function CarPoliceInfoForm({ response, match }) {
  const [showPhotoForm, setShowPhotoForm] = useState(false)
  const [successUpdate, setSuccessUpdate] = useState(false)
  const [marks, setMarks] = useState([])
  const [modelMarkMap, setModelMarkMap] = useState({ models: {}, marks: {} })
  const [models, setModels] = useState([])
  const formTop = useRef(null)
  const timer = useRef()

  const [bdStart, bdStop] = useMemo(() => {
    return [
      moment().add(-100, 'years').format('YYYY-MM-DD'),
      moment().add(-18, 'years').format('YYYY-MM-DD'),
    ]
  })

  const { request: authRequest, error: authError } = usePostRequest({
    data: {
      "username": process.env.REACT_APP_USER_LOGIN || "front_service",
      "password": process.env.REACT_APP_USER_PASSWORD || "humo_service_123"
    },
    url: authUrl,
    authAxios: true,
  })

  const { request: updateRequest } = usePutRequest({
    url: carUpdData,
  })

  const { request: updatePhoto, updatePhotoLoading } = usePostRequest({
    url: carUpdateData,
  })

  const formik = useFormik({
    initialValues: {
      holderFullName: `${response.holderLastname} ${response.holderFirstname} ${response.holderPatronymic}`,
      holderLastname: response.holderLastname,
      holderFirstname: response.holderFirstname,
      holderPatronymic: response.holderPatronymic,
      holderDocSeries: response.holderSeries,
      holderDocNumber: response.holderDocNumber,
      holderIssueDate: '',
      holderAddress: '',
      holderBirthday: moment(response.holderBirthday).format('YYYY-MM-DD'),
      carIssueYear: response.issueYear,
      gosNomer: response.gosNumber,
      holderPhone: response.holderPhone,
      vin: '',
      registerSvidet: '',
      carMark: '',
      carModel: '',
      drivers: [],
      stopCopy: false,
      photos: null,
      id: response['id'],
      policyNumber: response['policyNumber'],
      url: match.params.code,
    },
    validate: (values) => validate(values, hideConfirmForm),
    onSubmit: async (values) => {
      const val = createRequestDataV2(values)
      const data = new FormData()
      console.log(formik.values.carMark, formik.values.carModel)
      console.log(modelMarkMap)
      Object.keys(val).forEach(key => data.append(key, val[key]))
      data.append('policy_number', formik.values.policyNumber)
      data.append('id', formik.values.id)
      data.append('carMark', modelMarkMap.marks[formik.values.carMark])
      data.append('carModel', modelMarkMap.models[formik.values.carModel])

      if (formik.values.photos) {
        Object.keys(formik.values.photos).forEach((key) => {
          data.append(`files`, formik.values.photos[key])
        })
      }

      const { success: authSuccess, response: authResponse} = await authRequest()
      localStorage.setItem('token', authResponse.access)
      window.scrollTo(0, 0)
      if (authSuccess) {
        hideConfirmForm()
        const { success: updateSuccess } = await updateRequest({data: data})
        if (updateSuccess) {
          setSuccessUpdate(true)
        }
      }
    }
  })

  const marksRequest = useGetRequest({ url: markUrl, authAxios: true, })
  const modelRequest = useGetRequest({ url: modelUrl.replace('{product_id}', formik.values.carMark), authAxios: true, })

  const addDriver = useCallback(() => {
    formik.setFieldValue('drivers',
      [
        ...formik.values.drivers,
        {
          driverFullName: '',
          driverBd: '',
          accept: false,
          driverLastname: '',
          driverFirstname: '',
          driverPatr: '',
          id: moment().unix()
        },
      ])
  }, [formik.values])
  const copyValues = useCallback((i) => {
    const drivers = [ ...formik.values.drivers ]
    drivers[i].driverFullName = formik.values.holderFullName
    drivers[i].driverLastname = formik.values.holderLastname
    drivers[i].driverFirstname = formik.values.holderFirstname
    drivers[i].driverPatr = formik.values.holderPatronymic
    drivers[i].driverBd = formik.values.holderBirthday
    formik.setFieldValue('drivers', drivers)
    formik.setFieldValue('stopCopy', true)
  }, [formik.values])
  useEffect(() => {
    addDriver()
  }, []);
  const years = useMemo(() => {
    const data = []
    for (let i = 0; i < 20; i++) {
      data.push({
        label: moment().add(-i, 'years').year(),
        value: moment().add(-i, 'years').year(),
      })
    }
    return data
  }, [])

  const getMarksCallback = useCallback(async () => {
    const { success: authSuccess, response: authResponse} = await authRequest()
    if (authSuccess) {
      localStorage.setItem('token', authResponse.access)
    }
    const {success: markSuccess, response: markResponse} = await marksRequest.request()
    if (markSuccess) {
      const data = { ...modelMarkMap.marks }
      const values = []
      markResponse.forEach((item) => {
        values.push({
          value: item.syncId,
          label: item.name,
        })

        data[item.syncId] = item.name
      })

      setModelMarkMap({ ...modelMarkMap, marks: data })
      setMarks(values)
    }
  }, [formik.values])

  useEffect(() => {
    getMarksCallback()
  }, [])

  const getModelsCallback = useCallback(async (val) => {
    const { success: authSuccess, response: authResponse} = await authRequest()
    if (authSuccess) {
      localStorage.setItem('token', authResponse.access)
    }
    const {success: modelSuccess, response: modelResponse} = await modelRequest.request({
      url: modelUrl.replace('{product_id}', val),
    })
    if (modelSuccess) {
      const data = { ...modelMarkMap.models }
      const values = []
      modelResponse.forEach((item) => {
        values.push({
          value: item.syncId,
          label: item.name,
        })

        data[item.syncId] = item.code
      })

      setModelMarkMap({ ...modelMarkMap, models: data })
      setModels(values)
    }
  }, [formik.values])

  useEffect(() => {
    if (formik.values.carMark) {
      getModelsCallback(formik.values.carMark)
    }
  }, [formik.values.carMark]);

  const [showConfirmForm, hideConfirmForm] = useModal(
    <ModalCarConfirm close={() => {hideConfirmForm()}} approve={() => formik.handleSubmit()}/>
  )

  const { t } = useTranslation("auto")

  return (
    <div className="secondstep" ref={formTop}>
      {successUpdate && <div style={{color: 'rgb(27,185,9)', textAlign: 'center', fontSize: '32pt', fontWeight: 'bolder', marginTop: '120px'}}>Загрузка завершена успешно</div>}
      <FormikProvider value={formik}>
        <form action="" className="form secondform">
          <div className="form__row-wide">
            <Input name="holderFullName" placeholder={ t('holderFullNamePH') } isDisabled={true} title={ t('holderFullName') } />
          </div>
          <div className="form__row">
            <div className="form__innerrow-reverse">
              <Input name="holderDocSeries" placeholder={ t('holderDocSeries') } isDisabled={true} title={ t('holderDocSeries') } />
              <Input name="holderDocNumber" placeholder={ t('holderDocNumber') } isDisabled={true} title={ t('holderDocNumber') } />
            </div>
            <InputDate name="holderBirthday" min={bdStart} max={bdStop} placeholder={ t('holderBirthday') } isDisabled={false} title={ t('holderBirthday') } error={formik.errors['holderBirthday']} />
          </div>
          <div className="form__row">
            <InputDate name="holderIssueDate" min={bdStart} max={bdStop} placeholder={ t('holderIssueDate') } isDisabled={false} title={ t('holderIssueDate') } error={formik.errors['holderIssueDate']} />
            <Input name="holderAddress" placeholder={ t('holderAddressPH') } isDisabled={false} title={ t('holderAddress') } error={formik.errors['holderAddress']} />
          </div>
          <div className="form__row">
            <Input name="carIssueYear" placeholder={ t('carIssueYear') } isDisabled={true} title={ t('carIssueYear') } options={years} />
            <Input name="gosNomer" placeholder={ t('gosNomer') } isDisabled={true} title={ t('gosNomer') } error={formik.errors['gosNomer']} />
          </div>
          <div className="form__row">
            <Select name="carMark" placeholder={ t('carMark') } isDisabled={true} title={ t('carMark') } options={marks} />
            <Select name="carModel" placeholder={ t('carModel') } isDisabled={true} title={ t('carModel') } options={models} />
          </div>
          <div className="form__row">
            <Input name="vin" placeholder={ t('vin') } isDisabled={false} title={ t('vin') } error={formik.errors['vin']} />
            <Input name="registrSvidet" placeholder={ t('registrSvidet') } isDisabled={false} title={ t('registrSvidet') } error={formik.errors['registrSvidet']} />
          </div>
          <h2 className="auto__subtitle">{t('drivers')}</h2>
          {
            formik.values.drivers.map((item, i) => (
              <div className="auto__driver-card driver-card" key={item.id}>
                <div className="driver-card__num">{ (i + 1) }</div>
                {
                  !formik.values.stopCopy ? (
                    <button className="driver-card__button"
                            onClick={ (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              copyValues(i)
                            } }>
                      { t("copyHolderData") }
                    </button>
                  ) : null
                }
                <div className="form__row-wide">
                  <Input name={ `drivers[${ i }]driverFullName` } placeholder={ t('holderFullNamePH') }
                         isDisabled={ false } title={ t('holderFullName') }
                         error={formik.errors[`drivers[${i}]driverLastname`] || formik.errors[`drivers[${i}]driverFirstname`] || formik.errors[`drivers[${i}]driverPatronymic`]}
                         onChange={ (val) => {
                           clearTimeout(timer.current)
                           timer.current = setTimeout(() => {
                             const str = val.replace(/[ ]+/gm, ' ')
                             const value = str.split(' ')
                             formik.setFieldValue(`drivers[${i}]driverLastname`, value[0] || '')
                             formik.setFieldValue(`drivers[${i}]driverFirstname`, value[1] || '')
                             value.splice(0, 2)
                             formik.setFieldValue(`drivers[${i}]driverPatronymic`, (value && value.join(' ')) || '')
                           }, 300)
                         }} />
                </div>
                <div className="form__row">
                  <InputDate name={ `drivers[${ i }]driverBd` } placeholder={ t('holderBirthday') } isDisabled={ false }
                             title={ t('holderBirthday') } error={formik.errors[`drivers[${ i }]driverBd`]}/>
                </div>
                <Checkbox title={t('driverPeriodAccept')} name={ `drivers[${ i }]accept` } error={formik.errors[`drivers[${ i }]accept`]}/>
                <button className="driver-card__button driver-card__button-add"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    addDriver()
                  }}>
                  {t('addDriver')}
                </button>
              </div>
            ))
          }
        </form>
      </FormikProvider>
      {
        showPhotoForm ? <>
            <CarUpdateForm id={response['policyNumber']}
                           updatePhoto={(photos) => formik.setFieldValue('photos', photos) } />
            <Button onClick={showConfirmForm} title={t('common:save')} disabled={formik.errors.haveErrors} />
          </>:<>
            <Button onClick={() => setShowPhotoForm(val => !val)} title={t('loadPhoto')} />
            <Button onClick={showConfirmForm} title={t('loadPhotoLater')} disabled={formik.errors.haveErrors} />
          </>
      }

    </div>
  )
}
