import cn from "classnames"
import moment from "moment"
import React from 'react';
import { useField } from "formik"
import { useTranslation } from "react-i18next"

export default function InputDate({ title, name, placeholder, isDisabled, error, min, max }) {
  const [ { value }, { }, { setValue } ] = useField(name);
  const { t } = useTranslation('common');

  return (
    <div className={cn("input", {'input_error': error})}>
      <label className={ cn("input__label", {'label-valid': !error}) } htmlFor={ name }>
        { title }
        <div className="input_error">
          <span className="input_error-text">
            { t('wrongInput') }
          </span>
        </div>
      </label>
      <input id={ name } placeholder={ placeholder } className="input__input" type="date"
             value={value} disabled={isDisabled} aria-invalid={!isDisabled} min={min} max={max}
             onChange={(e) => setValue(moment(e.target.value).format('YYYY-MM-DD'))}/>
    </div>
  )
}
