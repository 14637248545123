import cn from "classnames"
import React, { useEffect, useRef, useState } from 'react';
import { useField } from "formik"

export default function Select({ title, name, placeholder, isDisabled, isRequired, options, error }) {
  const [ { value }, {  }, { setValue } ] = useField(name);
  const [ open, setOpen] = React.useState(false);
  const [labelVal, setLabelVal] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    for (let option of options) {
      if (option.value === value) {
        setLabelVal(option.label);
        return;
      }
    }
    setLabelVal(null);
  }, [value]);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return (
    <div className={ cn("select input", {'input_error': error, 'input__required': isRequired}) } ref={ref}>
      <label className={ cn("select__label", {'label-valid': !error}) } htmlFor={ name }>
        { title }
        <div className="input_error">
          <span className="input_error-text">
            { error }
          </span>
        </div>
      </label>
      <div>
        <div className={cn('select__current', {"select__current_opened": open})}
          onClick={() => setOpen(val => !val)}>
          {labelVal ?? placeholder}
        </div>
        <div className={cn('select__list', {"select__list_open": open})}>
          {options.map(({label, value: val}, i) => (
            <div className="select__item" key={i}
                 onClick={(e) => {
                   e.stopPropagation()
                   setValue(val)
                   setOpen(val => !val)
                 }}>
              {label}
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}
