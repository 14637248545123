import cn from "classnames"
import React from 'react';
import { useField } from "formik"

export default function Button({ title, onClick, disabled }) {
  return (
    <a className="auto__button btn auto__button-submit" href="#"
       onClick={(e) => {
         e.preventDefault()
         e.stopPropagation()
         if (!disabled) {
           onClick()
         }
       }}>
      {title}
    </a>
  )
}
