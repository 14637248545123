import * as yup from 'yup'
import moment from 'moment'

export const validateRules = {
  beginDate: yup.date().min(moment().startOf('day').add(1, 'day').toDate()),
  duration: yup.string().required(),
  markaTxt: yup.string().required(),
  modelTxt: yup.string().required(),
  vin: yup.string().required().matches(/^([a-zA-Z0-9]*)$/).min(9).max(17),
  bodyNumber: yup.string().required().matches(/^([a-zA-Z0-9]*)$/).min(9).max(12),
  carBodyType: yup.number().required(),
  policeSumInsured: yup.number().required().min(1),
  color: yup.string().optional(),
  gosNomer: yup.string().required().test('notTaxi', 'taxi', (value) => !/(TT|tt|ТТ|тт)/.test(value)).matches(/^[0-9]{4}[a-zA-Z]{2}[0-9]{2}$/),
  registrSvidet: yup.string().required().matches(/^[a-zA-Z]{2}[ ]?[0-9]{7}$/),
  holderFullName: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
  holderLastname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
  holderFirstname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
  holderPatronymic: yup.string().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/),
  holderGender: yup.string().required(),
  holderBirthday: yup.date().required().min(moment().add(-100, 'years').toDate()).max(moment().add(-18, 'years').toDate()),
  holderDocType: yup.string().required(),
  holderDocSeries: yup.string().required().matches(/^[a-zA-Z]{1,50}$/),
  holderDocNumber: yup.string().required().matches(/^[0-9]{1,15}$/),
  holderIssueDate: yup.string().required().min(moment().add(-70, 'years').toDate()).max(moment().toDate()),
  holderAddress: yup.string().required().matches(/^([0-9а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z-., ]*)$/).min(1),
  holderPhone: yup.string().required().matches(/^[+(]*992[)]+ \d\d-\d\d\d-\d\d\d\d$/),
  holderEmail: yup.string().required().email().matches(/^([^а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷ]*)$/),
  driverFullName: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
  driverLastname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
  driverFirstname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
  driverPatronymic: yup.string().optional().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/),
  docSeries: yup.string().required().matches(/^[a-zA-Z]{2}$/),
  docNumber: yup.string().required().matches(/^[0-9]{7}$/),
  driverCitizenship: yup.string().required(),
  driverBd: yup.date().required().min(moment().add(-100, 'years').toDate()).max(moment().add(-18, 'years').toDate()),
  driverGender: yup.string().required(),
  docIssueDate: yup.string().required().min(moment().add(-100, 'years').toDate()).max(moment().add(-1, 'year').toDate()),
  birthday: yup.date().required().min(moment().add(-100, 'years').toDate()).max(moment().add(-21, 'year').toDate()),
  gender: yup.string().required(),
  citizenship: yup.string().required(),
  accept: yup.bool().required().oneOf([true], 'Field must be checked'),
}

const fields = [
  'holderBirthday',
  'holderDocNumber',
  'holderIssueDate',
  'holderAddress',
  'vin',
  'registrSvidet',
]

const drivers = [
  'driverFullName',
  'driverLastname',
  'driverFirstname',
  'driverPatronymic',
  'driverBd',
  'accept',
]

export const validate = (values, hide) => {
  const errors = {}
  return errors
  fields.forEach((item) => {
    if (validateRules[item]) {
      if (!validateRules[item].isValidSync(values[item])) {
        if (['gosNomer'].indexOf(item) !== -1) {
          errors[item] = true
          try {
            validateRules[item].validateSync(values[item])
          } catch (e) {
            if (['notTaxi'].indexOf(e.type) !== -1) {
              errors[item] = e.message
            }
          }
        } else {
          errors[item] = true
        }
      }
    }
  })
  values.drivers.forEach((driver, i) => {
    drivers.forEach((item) => {
      if (validateRules[item]) {
        if (!validateRules[item].isValidSync(driver[item])) {
          errors[`drivers[${i}]${item}`] = true
        }
      }
    })
  })
  if (Object.keys(errors).length > 1) {
    errors.haveError = true
    hide()
  }
  return errors
}

export const createRequestDataV2 = (values) => {
  return {
    issueYear: values.carIssueYear,
    holderFirstname: values.holderFirstname,
    holderLastname: values.holderLastname,
    holderPatronymic: values.holderPatronymic,
    holderPhone: values.holderPhone,
    holderDocNumber: values.holderDocNumber,
    holderSeries: values.holderDocSeries,
    gosNumber: values.gosNomer,
    holderIssueDate: values.holderIssueDate,
    holderAddress: values.holderAddress,
    holderBirthday: values.holderBirthday,
    drivers: values.drivers,
    vin: values.vin,
    registrSvidet: values.registrSvidet,
    url: values.url,
    policy_number: values.policyNumber
  }
}
